import React, { FC } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import visual_1 from "../../images/monitoringScreens/visual_1.jpg";
import visual_2 from "../../images/monitoringScreens/visual_2.jpg";
import visual_3 from "../../images/monitoringScreens/visual_3.jpg";
import visual_4 from "../../images/monitoringScreens/visual_4.jpg";
import visual_5 from "../../images/monitoringScreens/visual_5.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const VisualMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="Visual monitoring"
        subtitle="Monitor web pages for any visual changes. Select a part of the screen or the whole page."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 visual-monitoring-page">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>
            {size ? (
              <video
                controls
                poster={visual_1}
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Visual_monitor_-_Walkthrough_1598174880.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                poster={visual_1}
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Visual_monitor_-_Walkthrough_1598174880.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Visual monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img src={visual_1} alt="Select visual monitoring tool" />
            </div>
            <p className="mb">
              The visual monitor is great for keeping an eye on your own website
              for visual issues, as well as keeping an eye on your competitor's
              product pages to be notified when new products are launched.
            </p>

            <div className="img-block">
              <img src={visual_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={visual_3}
                alt="Choose to monitor the whole page, or choose a specific selection"
              />
            </div>
            <p className="mb">
              Once the page has loaded, you can choose to monitor the whole
              page, or choose a specific selection.
            </p>

            <div className="img-block">
              <img
                src={visual_4}
                alt=" You can choose the frequency of the checks, your preferred notification channels, as well set custom start and end dates"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img src={visual_5} alt="Click start monitoring to get started" />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you when any visual changes are
              detected.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default VisualMonitoring;
